import { closestCorners, pointerWithin } from '@dnd-kit/core';
import type { Active, ClientRect } from '@dnd-kit/core';
import { DroppableContainer, RectMap } from '@dnd-kit/core/dist/store';
import type { Coordinates } from '@dnd-kit/utilities';
import { differenceInCalendarWeeks } from 'date-fns';
import { t } from 'i18next';
import { chain, indexOf, isString, sortBy } from 'lodash';
import type { LinkProps } from 'react-router-dom';

import { WORKSPACE_MODE } from '@/types/enums';
import { AxiosError } from 'axios';
import { PlannException } from '@/types/base-responses';

export type Week = {
  date: Date;
  weekNumber: number;
  isFirstWeekOfMonth: boolean;
  isOff: boolean;
};
export type MonthInYear = {
  year: number;
  month: string;
  weeks: Week[];
  index: number;
};

export function getCssVariable(name: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export const checkPasswordValidity = (
  value: string,
): Record<string, boolean> => {
  const isContainsNumber = /^(?=.*[0-9]).*$/;
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;

  return {
    length: Boolean(value?.length >= 8),
    special: isContainsSymbol.test(value),
    digit: isContainsNumber.test(value),
  };
};

/**
 * Calculates the number of pixels required to represent a time interval in weeks.
 *
 * @param {Date} endDate - The end date of the time interval.
 * @param {Date} startDate - The start date of the time interval.
 * @param {boolean} [endCompensation=false] - Indicates whether an additional pixel should be added to compensate for the end of the interval.
 * @returns {number} - The number of pixels required to represent the time interval.
 */

export const weeksToPixels = (
  endDate: Date,
  startDate: Date,
  endCompensation = false,
  weekSizeInPx: number,
) => {
  return (
    (differenceInCalendarWeeks(endDate, startDate) +
      (endCompensation ? 1 : 0)) *
    weekSizeInPx
  );
};

export const truncate = (string: string, length: number, end = '...') => {
  return string.length < length ? string : string.substring(0, length) + end;
};

export const cssVariablesByType = (type: string) => {
  switch (type) {
    case 'ghost':
      return '--color-neutral-1000';
    case 'danger':
      return 'white';
    case 'outline':
      return '--color-neutral-1000';
    case 'link':
      return '--color-neutral-1000';
    default:
      return 'white';
  }
};

export function roundNumber(num: number) {
  const rounded = Number(num.toFixed(1));
  if (rounded === Number(num)) {
    return Number(num);
  } else {
    return rounded;
  }
}

export function titleCase(str: string) {
  if (!str) return undefined;
  const [first, ...tail] = str.toLocaleLowerCase();
  return first.toUpperCase() + tail.join('');
}

/**
 * This function splits a full name into first name and last name.
 * It assumes that the full name is a single string where the words are separated by spaces.
 * The first word is considered the first name, and all the remaining words are considered the last name.
 *
 * @param {string} fullName - The full name to be split.
 * @returns {object} - An object with two properties: firstName and lastName.
 * @throws {Error} - If the fullName parameter is not a string.
 */
export function nameSplit(fullName: string) {
  if (!isString(fullName)) {
    throw new Error('The fullName parameter must be a string');
  }

  // Removes extra spaces and splits the string into an array of words
  const words = chain(fullName)
    .trim() // Removes spaces at the beginning and end of the string
    .split(/\s+/) // Splits the string into an array of words separated by one or more spaces
    .value();

  // If the array contains only one element, returns only the first name
  if (words.length === 1) {
    return { firstName: words[0], lastName: '' };
  }

  // If the array contains more than two elements, considers the first as the first name and the rest as the last name
  if (words.length > 2) {
    return {
      firstName: words[0],
      lastName: chain(words)
        .drop(1) // Removes the first element of the array
        .join(' ') // Joins the remaining elements into a string separated by spaces
        .value(),
    };
  }

  // If the array contains exactly two elements, considers the first as the first name and the second as the last name
  return { firstName: words[0], lastName: words[1] };
}

/**
 * This function generates a universally unique identifier (UUID).
 * A UUID is a 128-bit number used to uniquely identify some object or entity on the Internet.
 * This function generates a UUID compliant with RFC4122 version 4.
 *
 * @returns {string} - The generated UUID.
 */
export function generateUUID() {
  // Get the current timestamp in milliseconds.
  let d = new Date().getTime();

  // If the high-precision timer is available, use it to get the current time in milliseconds.
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now();
  }

  // The UUID is generated by replacing each 'x' and 'y' in the template string with a random hexadecimal digit.
  // For 'y', the random hexadecimal digit is from the set [8, 9, A, or B].
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

const units = ['b', 'kb', 'MB', 'GB'];

export function niceBytes(x: number) {
  let l = 0,
    n = parseInt(String(x), 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

export function sortByReferenceArray<T>(arrayToSort: T[], referenceArray: T[]) {
  return sortBy(arrayToSort, (item) => indexOf(referenceArray, item));
}

/**
 * This function performs a linear mapping from one range to another.
 * It takes a value within a known range (inMin to inMax) and maps it to a new range (outMin to outMax).
 *
 * @param {number} value - The input value to be mapped.
 * @param {number} inMin - The lower bound of the input range.
 * @param {number} inMax - The upper bound of the input range.
 * @param {number} outMin - The lower bound of the output range.
 * @param {number} outMax - The upper bound of the output range.
 * @returns {number} - The input value mapped to the output range.
 */
export function linearMap(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

// Example usage:
// Suppose we have a temperature sensor that outputs values from 0 to 1023,
// and we want to map this to a range from -10 to 40 (degrees Celsius).
// If the sensor outputs a value of 512, we can map this to the temperature range like so:

// const sensorValue = 512;
// const temperature = linearMap(sensorValue, 0, 1023, -10, 40);
// This will output: "The temperature is 15 degrees Celsius."

/**
 * This function is used to shrink large numbers into a more readable format.
 * It uses the metric prefixes to denote the size of the number.
 *
 * @param {number} data - The number to be shrunk.
 * @returns {string} - The shrunk representation of the number.
 */
export function shrinkValue(data: number): string {
  // An array of metric prefixes, in order of magnitude.
  const valueSize = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

  // If the number is less than 1000, it is returned as is.
  if (data < 1000) return `${Number(data.toFixed(1))}`;
  else {
    // The number is divided by 100 to start the shrinking process.
    let v = data / 100;
    // A counter to keep track of the number of times the number has been divided.
    let round = 0;

    // The number is continuously divided by 10 until it is less than 10.
    while (v >= 10) {
      v = v / 10;
      round++;
    }
    // The shrunk number is returned, appended with the appropriate metric prefix.
    // If the number is 0, '0' is returned.
    return v ? `${Number(v.toFixed(1))}${valueSize[round]}` : '0';
  }
}

/**
 * This function generates a label for a timeline mode based on the provided parameters.
 *
 * @param {Object} params - An object containing the parameters for the function.
 * @param {WORKSPACE_MODE} params.mode - The current workspace mode.
 * @param {boolean} params.isCompact - A boolean indicating whether the timeline is in compact mode.
 * @param {boolean} [params.isPlural=false] - An optional boolean indicating whether the label should be in plural form.
 * @param {boolean} [params.isShort=false] - An optional boolean indicating whether the label should be in short form.
 *
 * @returns {string} - A string representing the timeline mode label.
 *
 * The function works as follows:
 * 1. It takes an object as a parameter, which contains the current workspace mode, a boolean indicating whether the timeline is in compact mode, and optional booleans indicating whether the label should be in plural or short form.
 * 2. It uses the `t` function (which is typically used for internationalization) to generate a label based on these parameters.
 * 3. The label is constructed by concatenating several strings:
 *    - 'timeline:blockAllocation' is the base of the label.
 *    - If `isCompact` is true, 'Compact' is appended to the base.
 *    - If `isCompact` is false and `isPlural` is true, 'Plural' is appended to the base.
 *    - If `isCompact` is false and `isPlural` is false, 'Singular' is appended to the base.
 *    - If `isShort` is true, 'Short' is appended to the base.
 *    - Finally, the `mode` is appended to the end of the label.
 * 4. The constructed label is returned.
 */
export function getTimelineModeLabel({
  mode,
  isCompact,
  isPlural = false,
  isShort = false,
}: {
  mode: WORKSPACE_MODE;
  isCompact: boolean;
  isPlural?: boolean;
  isShort?: boolean;
}) {
  // The `t` function is used to generate a label based on the provided parameters.
  return t(
    `timeline:blockAllocation${
      // If `isCompact` is true, 'Compact' is appended to the base.
      // If `isCompact` is false and `isPlural` is true, 'Plural' is appended to the base.
      // If `isCompact` is false and `isPlural` is false, 'Singular' is appended to the base.
      isCompact ? 'Compact' : isPlural ? 'Plural' : 'Singular'
    }${isShort ? 'Short' : ''}${mode}`, // If `isShort` is true, 'Short' is appended to the base. Finally, the `mode` is appended to the end of the label.
  );
}

export function pointerAndClosestCorners(collisionArgs: {
  active: Active;
  collisionRect: ClientRect;
  droppableRects: RectMap;
  droppableContainers: DroppableContainer[];
  pointerCoordinates: Coordinates | null;
}) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(collisionArgs);
  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }
  // If there are no collisions with the pointer, return Closest Corners algorithm
  return closestCorners(collisionArgs);
}

export function createChunks<T>(
  data: T[],
  chunkSize: number,
): T[][] | undefined {
  if (!data?.length) return undefined;
  else {
    const dt: T[] = [...data];
    const ret: T[][] = [];
    while (dt.length) ret.push(dt.splice(0, chunkSize));
    return ret;
  }
}

export function normalizeLinkProps(link: LinkProps | string): LinkProps {
  return typeof link === 'string' ? { to: link } : link;
}

export function isAxiosError<T>(error: any): error is AxiosError<T> {
  return !!error.isAxiosError;
}

export function getErrorMessage<T>(
  error: AxiosError<PlannException<T>>,
): string | undefined {
  return error?.response?.data?.message;
}
